<footer id="footer"
		class="ym-clearfix">
	<ng-container *ngIf="user(); else notLoggedIn">
		<div class="ym-hlist list">
			<ul>
				<li>
					<a routerLink="/workflow.do?to=home">
						{{ 'frame.footer.home' | msgKeyCached }}
					</a>
				</li>
				<li>
					<a routerLink="/workflow.do?from=menu&to=faq">
						{{ 'frame.footer.faq' | msgKeyCached }}
					</a>
				</li>
				<li>
					<a [routerLink]="'frame.footer.contact.email' | msgKey">
						{{ 'frame.footer.contact' | msgKeyCached }}
					</a>
				</li>
				<li>
					<a href="https://amisonline.allianz.de/"
					   target="_blank">
						AMIS Online
					</a>
				</li>
				<li>
					<a href="https://www.allianz.de/impressum/"
					   target="_blank">
						{{ 'frame.footer.impressum' | msgKeyCached }}
					</a>
				</li>
				<li>
					<bas-footer-terms-of-use></bas-footer-terms-of-use>
				</li>
				<li>
					<a routerLink="/help.do?currentTopic=homepage/portalverantwortung">
						Portalverantwortung
					</a>
				</li>
			</ul>
			<ul>
				<li>
					<bas-footer-history></bas-footer-history>
				</li>
				<li>
					<bas-footer-privacy-policy></bas-footer-privacy-policy>
				</li>
			</ul>
		</div>
		<div class="bottom">
			<bas-footer-serverinfo></bas-footer-serverinfo>
		</div>
	</ng-container>
</footer>

<ng-template #notLoggedIn>
	<!-- 	page[@module='login' or @module='invalid' or @type='noframes'] -->
	<div class="bottom overlap">
		<bas-footer-impressum></bas-footer-impressum>
		<bas-footer-terms-of-use></bas-footer-terms-of-use>
		<bas-footer-privacy-policy></bas-footer-privacy-policy>
	</div>
	<!-- 	page -->
</ng-template>